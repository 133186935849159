
import { IonItem, IonLabel, IonCard, IonCheckbox } from "@ionic/vue";
import { useRoute, useRouter } from "vue-router";
import {ref, computed, defineComponent} from "vue";
import { getMediaItem, Journey, updateJourney } from "@/storage/DataStorage";
import { chevronBack, help, ellipsisVertical, leaf, addSharp } from "ionicons/icons";
import { openJourneyHelpPagesModal } from "@/services/InformationPagesService";
import { useStore } from 'vuex';
import { getTranslatedNumber } from './translate';
import {getImageDataUri} from "@/utilities/Compatibility";
export default defineComponent({
    name: "Journeys",
    components: {
        IonItem,
        IonLabel,
        IonCard,
        IonCheckbox,
    },
    async ionViewWillEnter() {
        this.showJourneys = false;
        const journeySet = await this.$store.getters.getJourneySet(this.currentJourneySetId);
        if (!journeySet) {
            await this.$router.push({ name: `fields` });
            return;
        }
        this.subHeaderText = journeySet.name;
        const headerImage = await getMediaItem(journeySet.iconId);
        this.$store.dispatch("setHeaderImage", getImageDataUri(headerImage.content));
        this.$store.dispatch("setHeaderTitle", "");
        this.journeys = await this.$store.getters.getJourneys(this.currentJourneySetId);
        this.showJourneys = true;
    },
    setup() {
        const route = useRoute();
        const store = useStore();
        const journeys = ref([] as Journey[]);
        const currentFieldId = computed(() => route.params.fieldId);
        const currentJourneySetId = computed(() => route.params.journeySetId);
        const subHeaderText = ref("" as string);
        const showJourneys = ref(false);

        const router = useRouter();
        const routeToJourney = (journeyId: string) => {
            router.push({
                name: "journey",
                params: { fieldId: currentFieldId.value, journeySetId: currentJourneySetId.value, journeyId: journeyId },
            });
        };

        async function setCompleteStatus(journey: Journey) {
            journey.isComplete = !journey.isComplete;
            await updateJourney(journey);
        }

        return {
            subHeaderText,
            journeys,
            routeToJourney,
            chevronBack,
            help,
            ellipsisVertical,
            leaf,
            addSharp,
            setCompleteStatus,
            currentJourneySetId,
            openJourneyHelpPagesModal,
            showJourneys,
            localeMessages: computed(() => store.getters.getLocaleMessages()),
            returnRoute: computed(() => `/fields/${currentFieldId.value}/journey-sets/`),
            getTranslatedNumber
        };
    },
});
