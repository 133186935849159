import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fd7435f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page-container" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_base_layout = _resolveComponent("base-layout")!

  return (_openBlock(), _createBlock(_component_base_layout, {
    returnRoute: _ctx.returnRoute,
    shouldShowHelpIcon: true,
    onHelp: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openJourneyHelpPagesModal(_ctx.localeMessages))),
    subHeaderText: _ctx.subHeaderText
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.journeys, (journey) => {
          return _withDirectives((_openBlock(), _createBlock(_component_ion_card, {
            key: journey,
            class: "journey-card",
            onClick: _withModifiers(($event: any) => (_ctx.routeToJourney(journey.id)), ["self"])
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, {
                lines: "none",
                class: "custom-item",
                onClick: _withModifiers(($event: any) => (_ctx.routeToJourney(journey.id)), ["self"])
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: "journey-number",
                    onClick: ($event: any) => (_ctx.routeToJourney(journey.id))
                  }, _toDisplayString(_ctx.getTranslatedNumber(journey.order)), 9, _hoisted_2),
                  _createElementVNode("div", {
                    onClick: ($event: any) => (_ctx.routeToJourney(journey.id)),
                    class: "journey-text"
                  }, [
                    _createVNode(_component_ion_label, { class: "journey-title ion-text-wrap" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(journey.name), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_ion_label, { class: "journey-description" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(journey.description), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ], 8, _hoisted_3),
                  _createVNode(_component_ion_checkbox, {
                    class: "complete-checkbox",
                    checked: journey.isComplete,
                    slot: "end",
                    onClick: ($event: any) => (_ctx.setCompleteStatus(journey))
                  }, null, 8, ["checked", "onClick"])
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 2
          }, 1032, ["onClick"])), [
            [_vShow, _ctx.showJourneys]
          ])
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["returnRoute", "subHeaderText"]))
}